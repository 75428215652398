:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --green: hsl(144, 69%, 56%);
  --green-active: hsl(144, 69%, 46%);
  --green-disabled: hsl(144, 100%, 95%);
  --yellow: hsl(36, 90%, 59%);
  --yellow-disabled: hsl(36, 100%, 94%);
  --yellow-active: hsl(36, 90%, 49%);
  --purple: hsl(267, 59%, 55%);
  --purple-active: hsl(267, 59%, 45%);
  --blue: hsl(209, 89%, 64%);
  --blue-active: hsl(209, 89%, 54%);
  --red: hsl(3, 100%, 63%);
  --red-disabled: hsl(4, 100%, 97%);
  --red-active: hsl(3, 100%, 53%);
  --grey-light: hsl(0, 0%, 91%);
  --grey-light-3: hsl(0, 0%, 80%);
  --grey-light-1: hsl(210, 17%, 98%);
  --grey-light-2: hsl(210, 5%, 61%);
  --grey-dark: hsl(210, 11%, 15%);
  --grey-dark-1: hsl(240, 10%, 59%);

  --shadow-smooth: 0 0 20px rgba(0, 0, 0, 0.088);

  --input-height: 4rem;

  --font-xsm: 1.3rem;
  --font-sm: 1.5rem;
  --font-md: 1.7rem;
  --font-lg: 1.9rem;
  --font-xl: 2.2rem;
  --font-xxl: 2.5rem;

  --radius-md: 5px;

  --button-md: 20rem;
  --button-sm: 15rem;

  --transitios-normal: all 0.3s;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  line-height: 1.65;
  text-rendering: optimizeLegibility;
  background-color: var(--grey-light-1);
  scroll-behavior: smooth;
  -webkit-font-smooth: antialiased;
  -webkit-tap-highlight-color: transparent;
  font-family: "Poppins", sans-serif;
}
html {
  font-size: 62.5%;
}
input {
  color: var(--grey-dark);
  font-weight: 300;
}
@media (max-width: 900px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 800px) {
  html {
    font-size: 57%;
  }
}
@media (max-width: 700px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 52.5%;
    --input-height: 5rem !important;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 50%;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
